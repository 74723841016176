import React from 'react';

const Visual = () => {
  return (
    <div>
      <div className="flex py-4">
        <article className="tile is-child">
          <img
            src="/img/work/bottles_paint.jpeg"
            alt="Bottles up outside wall"
          />
        </article>
      </div>
      <div className="flex py-4">
        <article className="tile is-child">
          <img src="/img/work/spray.jpg" alt="Spray paint" />
        </article>
      </div>
      <div className="flex">
        <article className="tile is-child">
          <img src="/img/work/bottles.png" alt="Bottles" />
        </article>
        <article className="tile is-child">
          <img src="/img/work/wine_love.jpg" alt="Cork" />
        </article>
      </div>
      <div className="flex">
        <div className="tile flex flex-col is-vertical">
          <div className="tile mb-4 resp-container">
            <iframe
              className="resp-iframe"
              src="https://player.vimeo.com/video/482071356"
              title="BottlesUp! Empty bottles..."
              frameBorder="0"
              allowFullScreen={true}
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            />
          </div>
        </div>
        <div className="tile is-parent">
          <article className="p-4 tile is-child">
            <img src="/img/work/bottles_up_door.jpeg" alt="" />
          </article>
        </div>
      </div>
      <div className="tile py-4">
        <img src="/img/work/yosemite.jpeg" alt="Yosemite" />
      </div>
      <div className="md:flex">
        <div className="tile is-ancestor mb-4 md:mb-2">
          <div className="tile is-vertical">
            <div className="columns resp-container">
              <iframe
                className="resp-iframe"
                src="https://player.vimeo.com/video/921290294"
                title="Egypt"
                frameBorder="0"
                allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              />
            </div>
          </div>
        </div>

        <div className="tile is-ancestor">
          <div className="tile is-vertical">
            <div className="columns resp-container">
              <iframe
                className="resp-iframe"
                src="https://player.vimeo.com/video/293829408"
                title="Iceland"
                frameBorder="0"
                allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:flex">
        <div className="tile is-ancestor mb-4 md:mb-2">
          <div className="tile is-vertical">
            <div className="columns resp-container">
              <iframe
                className="resp-iframe"
                src="https://player.vimeo.com/video/293829352"
                title="Ireland"
                frameBorder="0"
                allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              />
            </div>
          </div>
        </div>

        <div className="tile is-ancestor">
          <div className="tile is-vertical">
            <div className="columns resp-container">
              <iframe
                className="resp-iframe"
                src="https://player.vimeo.com/video/122582434"
                title="Alyson Meister"
                frameBorder="0"
                allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visual;
